// import { getCloudinaryURLWithParams } from "@utils/cloudinary";

// export const title = "Holidaying | Book trips with influencers and locals";
// export const description =
// "From an Insta post to a trip, Holidaying enables you to discover and book trips by content creators, locals and influencers all over the world";
// export const canonical = "https://holidaying.travel";
// export const openGraph = {
//   url: "https://holidaying.travel/search",
//   title: "Book trips with influencers and locals",
//   description:
//     "From an Insta post to a trip, Holidaying enables you to discover and book trips by content creators, locals and influencers all over the world",
//   images: [
//     {
//       url: getCloudinaryURLWithParams(
//         "https://firebasestorage.googleapis.com/v0/b/holidaying-prod.appspot.com/o/landing%2F1.png?alt=media&token=76ef7fc9-5ac6-4918-940f-9df4e16d8b7b",
//         800
//       ),
//       width: 800,
//       alt: "Surrounded by nature",
//     },
//   ],
//   site_name: "Holidaying",
// };

export const fbId = "1303481443920011";
